import React, { useState } from 'react'
import { Container, InputGroup, Row, Col, Form, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'

function Aisearch() {
  // const [selectedId, setselectedId] = useState('')
  const [tempSearch, setTempSearch] = useState('')
  const [search, setSearch] = useState('')
  const [focus, setFocus] = useState(false)

  const [selected, setSelected] = useState([])

  const tags = [
    '宇宙',
    '行銷',
    '整合',
    '訪談',
    '潮網',
    '外星文明',
    '全球化',
    '三體',
    '科學家',
    '技術',
    '外星人',
    '整合行銷',
    '能源',
    '研發',
    '台灣',
    '太空創新',
    '物理學家',
    '觀察',
    '腦波',
    '寵物',
    '矩陣力學',
    '科學料理',
    '食物',
    '飲食習慣',
    '火星',
    '執行長',
    '國際量子年',
    '甲烷',
    '專業人士',
    '議題的影響力',
    '臺灣未來的經濟格局',
    '星際通訊',
    '數學科普',
    '聽力度數',
    '蛋白質',
    '演算法',
    '破解密碼',
    '天線數',
    '人口',
    '北極',
    '原初黑洞',
    '外星起源假說',
    '演講分享',
    '幻影貓',
    '朋友',
    '量子糾纏',
    '升溫海水位上升',
    '挖掘恐龍',
    '微中子觀測站',
    '侏羅紀',
    '極光',
    '行程表',
    '香水',
    '未來',
    '米酵菌酸',
    'Gemini',
    '地質構造',
    '紐西蘭',
    '人造太陽',
    '思想實驗室',
    '古典物理',
    '蟻人',
    '直播',
    '耳塞',
    '衝擊波',
    '西裝',
    '干擾',
    '臺北大學',
    '葡萄酒',
    '運算次數',
    '火箭公司',
    '鯨魚化石發掘跡形',
    '有害物質',
    '募資',
    '大腦發展',
    '音樂',
    '核酸發生斷流',
    '阿茲海默症元兇',
    'GPT4',
    '美妝',
    '信任度',
    '戲劇化',
    '價值',
    '主題分享',
    '著作權',
    '訂製品牌',
    '薛丁格的貓',
    '洲際飛彈',
    '環評報告',
    '颱風',
    '導流',
    '醫美',
    '碳',
    '水力發電',
    '國立資源科學館',
    '地球系統',
    '能量貨幣',
    '考古學',
    '黑洞形成',
    '影片創作',
    '休息與睡眠',
    '日本',
    '鯊魚牙齒',
    '短片',
    '大氣污染',
    '泛科學院',
    '高解析度的技術',
    '睡眠問題',
    '塑膠製品',
    '電解水',
    '肩胛骨',
    '美國',
    '氣候變遷',
    '諾貝爾物理學',
    '神經細胞',
    '末日',
    '天文領域',
  ]

  const aiSearchTags = tags.map((tag) => (
    <Button
      variant="outline-chelonia"
      className="w-auto mx-1 my-1"
      style={{ flexWrap: 'wrap' }}
      size="sm"
      key={tag}
      onClick={() => setSelected([...selected, tag])}
      disabled={selected.includes(tag)}
    >
      {tag}
    </Button>
  ))

  console.log(search)

  return (
    <Container
      fluid
      className="d-flex flex-column px-5"
      style={{ height: '100%', width: '100vw' }}
    >
      <Row className="px-3" style={{ height: '10%' }}>
        <Col xs={12} className="d-flex">
          <h5 className="my-auto text-chelonia-light fw-bold">AI 逐字稿搜尋</h5>
        </Col>
      </Row>
      <Row className="w-100 px-5" style={{ height: '20%' }}>
        <Col xs={12} className="mt-auto p-5 w-100">
          <InputGroup className="w-100">
            {/* <Form.Control
              placeholder="輸入關鍵字以搜尋影片..."
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
              value={tempSearch}
              onChange={(event) => setTempSearch(event.target.value)}
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
              onKeyDown={(event) => {
                if (
                  event.key === 'Enter' &&
                  !event.nativeEvent.isComposing &&
                  focus
                )
                  setSearch(tempSearch)
              }}
              /> */}
            <div
              className="input-container"
              style={{
                minWidth: '95%',
                maxWidth: '95%',
                overflow: 'scroll',
              }}
            >
              {selected.map((key) => (
                <div className="input-tag d-flex" key={key}>
                  <span className="text-nowrap">{key}</span>
                  <FontAwesomeIcon
                    className="ms-2 fs-7 my-auto"
                    style={{
                      cursor: 'pointer',
                    }}
                    icon={faTimes}
                    onClick={() =>
                      setSelected(selected.filter((s) => s !== key))
                    }
                  />
                </div>
              ))}
              <Form.Control
                style={{
                  minWidth: '10vw',
                }}
                placeholder="輸入關鍵字以搜尋影片..."
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                value={tempSearch}
                onChange={(event) => setTempSearch(event.target.value)}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                onKeyDown={(event) => {
                  if (
                    event.key === 'Enter' &&
                    !event.nativeEvent.isComposing &&
                    focus &&
                    !selected.includes(tempSearch)
                  ) {
                    setSelected([...selected, tempSearch])
                    setTempSearch('')
                  }
                }}
              />
            </div>
            <Button
              disable
              variant="chelonia"
              id="button-addon2"
              title="搜尋  (此功能施工中 ⛏︎)"
              onClick={() =>
                setSearch(Object.keys(selected).filter((key) => selected[key]))
              }
              // onClick={() => setSearch(tempSearch)}
            >
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          </InputGroup>
        </Col>
      </Row>
      <div style={{ height: '70%' }}>
        <div className="pe-0 px-5">{aiSearchTags}</div>
      </div>
    </Container>
  )
}

export default Aisearch
