import React from 'react'
import { Container, Row } from 'react-bootstrap'
import { MaterialLists } from '../components'

function MaterialManagement() {
  return (
    <Container fluid className="h-100 d-flex flex-column">
      <Row className="h-100">
        <MaterialLists />
      </Row>
    </Container>
  )
}

export default MaterialManagement
