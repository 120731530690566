/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faCircleExclamation,
  faCircleUser,
  faDownload,
  faPenToSquare,
  faSearch,
  faTimes,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import {
  Row,
  Col,
  Button,
  ListGroup,
  ListGroupItem,
  Form,
  Modal,
  InputGroup,
  Container,
  Card,
} from 'react-bootstrap'
import { UploaderContext } from '../components/ContextProvider'

function AutoComplete({ setting }) {
  const { text, options, placeholder, show, onFocus, handleSelect } = setting
  const [search, setsearch] = useState('')
  const selected = useMemo(
    () => options.filter(({ checked }) => checked),
    [options]
  )

  return (
    <div
      className="w-100 position-relative mb-2"
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
      aria-hidden
    >
      <div className="d-flex flex-nowrap w-100">
        <div className="d-flex px-2">
          <Form.Label className="my-auto text-nowrap">{text}</Form.Label>
        </div>
        <div className="flex-fill">
          <div className="input-container">
            {selected.map((s) => (
              <div className="input-tag d-flex">
                <span>{s.label}</span>
                <FontAwesomeIcon
                  className="ms-2 fs-7 my-auto"
                  style={{
                    cursor: 'pointer',
                  }}
                  icon={faTimes}
                  onClick={() => handleSelect(s.value)}
                />
              </div>
            ))}
            <Form.Control
              className="p-0"
              onFocus={onFocus}
              placeholder={show || selected.length ? '' : placeholder}
            />
          </div>
        </div>
      </div>
      <div
        className="position-absolute w-100 border"
        style={{
          top: '100%',
          height: '500px',
          display: show ? 'block' : 'none',
          zIndex: '999999',
          backgroundColor: 'white',
        }}
      >
        <div className="mb-2 p-2 w-100 d-flex flex-column">
          <InputGroup
            style={{ borderRadius: '.25rem' }}
            className="border rounded-lg"
            size="sm"
          >
            <Form.Control
              type="text"
              placeholder="Search..."
              aria-label="Search..."
              aria-describedby="btnGroupAddon"
              title="輸 入 關 鍵 字 搜 尋"
              value={search}
              onChange={(e) => setsearch(e.target.value)}
            />
          </InputGroup>
          <hr />
          <div
            className="scrollbarShow"
            style={{ maxHeight: '25rem', overflowY: 'auto' }}
          >
            {options
              .filter(({ label }) => !search || label.includes(search))
              .map(({ label, value, checked }) => (
                <>
                  <ListGroupItem
                    title={label}
                    className="px-3 py-0 d-flex"
                    key={value}
                    href=""
                    onClick={() => handleSelect(value)}
                  >
                    <span>{label}</span>
                    {checked && (
                      <FontAwesomeIcon
                        className="ms-auto my-auto"
                        icon={faCheckCircle}
                      />
                    )}
                  </ListGroupItem>
                  <hr />
                </>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

function DeleteModal({ setting }) {
  const { show, name, handleClose } = setting

  return (
    <Modal
      style={{ zIndex: '1501' }}
      show={show}
      onHide={() => handleClose()}
      className="py-2 px-4"
    >
      <Modal.Header closeButton />
      <Modal.Body className="p-4">
        <div className="d-flex">
          <FontAwesomeIcon
            className="px-0 m-auto text-chelonia text-center"
            style={{
              height: '100px',
            }}
            icon={faCircleExclamation}
          />
        </div>
        <h5 className="text-center lh-lg text-chelonia">
          <br />
          警告！刪除後無法復原！
          <br />
          仍要刪除
          <span className="text-danger">{`「${name}」影片`}</span>
          嗎？
        </h5>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button
          className="ms-auto me-2"
          style={{ boxShadow: 'none' }}
          variant="secondary"
          onClick={() => handleClose()}
        >
          取 消
        </Button>
        <Button
          className="me-auto"
          style={{ boxShadow: 'none' }}
          variant="chelonia-dark"
          onClick={() => handleClose(true)}
        >
          確 認
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

function SettingModal({ setting }) {
  const { show, handleClose } = setting
  const [hashtagOptions, sethashtagOptions] = useState([
    { label: 'tag1', value: 'tag1' },
    { label: 'tag2', value: 'tag2' },
    { label: 'tag3', value: 'tag3' },
  ])
  const handleSelect = (e) => {
    sethashtagOptions(
      hashtagOptions.map(({ label, value, checked }) =>
        value === e
          ? { label, value, checked: !checked }
          : { label, value, checked }
      )
    )
  }

  const [focus, setfocus] = useState(false)

  return (
    <Modal
      style={{ zIndex: '1501' }}
      show={show}
      onHide={() => handleClose()}
      className="py-2 px-4"
      onClick={() => setfocus(false)}
    >
      <Modal.Header closeButton className="h5">
        Youtube 設定
      </Modal.Header>
      <Modal.Body className="px-4 py-0">
        <React.Fragment key="">
          <Form.Label className="mb-1 mt-3 fw-bold text-chelonia">
            帳號設定
          </Form.Label>
          <InputGroup className="d-flex">
            <Button variant="outline-chelonia" className="w-100 text-center">
              Sign in with
              <FontAwesomeIcon
                className="text-danger fs-5 px-2"
                icon={faYoutube}
              />
              Youtube
            </Button>
          </InputGroup>
        </React.Fragment>
        <React.Fragment key="">
          <Form.Label className="mb-1 mt-3 fw-bold text-chelonia">
            個人設定
          </Form.Label>
          <Card className="bg-light border p-3 fs-7">
            <Row className="justifu-content-around fs-6 fw-bold text-chelonia-light">
              <Col xs={6}>預設頻道</Col>
              <Col xs={6} className="text-end">
                <FontAwesomeIcon icon={faCircleUser} />
                &ensp; USER_NAME
              </Col>
            </Row>
            <Row className="px-4 py-2">
              這頻道是 CHANNEL_NAME 預設的 YouTube 頻道。所有自動上傳的
              highlight 會上傳至此頻道。
            </Row>
            <hr className="my-2 " />
            <Row className="justifu-content-around fs-6 fw-bold text-chelonia-light">
              <Col xs={6}>自動上傳所有 Highlight</Col>
              <Col xs={6} className="text-end">
                <Form.Check type="switch" id="custom-switch" />
              </Col>
            </Row>
            <Row className="px-4 py-2">
              如果選擇，所有輸出的 highlight 將會自動上傳為私人影片。
            </Row>
          </Card>
        </React.Fragment>
        <React.Fragment key="">
          <Form.Label className="mt-3 mb-2 fw-bold text-chelonia">
            上傳預設值
          </Form.Label>
          <Card className="bg-light border px-2 py-3 fs-7">
            <InputGroup className="mb-2">
              <Form.Label className="px-2 my-auto">標題</Form.Label>
              <Form.Control readOnly size="sm" type="text" />
            </InputGroup>
            <InputGroup className="mb-2">
              <Form.Label className="px-2 my-auto">說明</Form.Label>
              <Form.Control readOnly size="sm" type="text" />
            </InputGroup>
            <InputGroup className="mb-2">
              <Form.Label className="px-2 my-auto">類別</Form.Label>
              <Form.Select size="sm" aria-label="請選擇類別">
                <option>請選擇類別...</option>
                <option value="1">電影與動畫</option>
                <option value="2">汽車與車輛</option>
                <option value="3">音樂</option>
                <option value="4">寵物與動物</option>
                <option value="5">運動</option>
                <option value="6">短片</option>
                <option value="7">旅行與生活</option>
                <option value="8">遊戲</option>
                <option value="9">Video Bloggin</option>
                <option value="10">人物與網誌</option>
                <option value="11">喜劇</option>
                <option value="12">娛樂</option>
                <option value="13">新聞與政治</option>
                <option value="14">DIY 教學與生活風格</option>
                <option value="15">教育</option>
                <option value="16">科學與技術</option>
                <option value="17">電影</option>
                <option value="18">動畫/動漫</option>
              </Form.Select>
            </InputGroup>
            {/* <InputGroup className="mb-2">
              <Form.Label className="px-2 my-auto">標記</Form.Label>
              <Form.Control
                readOnly
                size="sm"
                type="text"
                placeholder="hashtag 多選輸入框..."
              />
            </InputGroup> */}
            <AutoComplete
              setting={{
                text: '標記',
                placeholder: 'hashtag 多選輸入框...',
                options: hashtagOptions,
                handleSelect: (e) => handleSelect(e),
                show: focus,
                onFocus: () => setfocus(true),
              }}
            />
            <InputGroup className="mb-2">
              <Form.Label className="px-2 my-auto">權限</Form.Label>
              <Form.Select size="sm" aria-label="請選擇類別">
                <option>請選擇權限...</option>
                <option value="1">公開</option>
                <option value="2">私人</option>
                <option value="3">Unlisted</option>
              </Form.Select>
            </InputGroup>
          </Card>
        </React.Fragment>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button
          className="ms-auto"
          style={{ boxShadow: 'none' }}
          variant="secondary"
          onClick={() => handleClose()}
        >
          取 消
        </Button>
        <Button
          className="me-auto"
          style={{ boxShadow: 'none' }}
          variant="chelonia-dark"
          onClick={() => handleClose()}
        >
          儲 存
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

function Hightlights() {
  // const navigate = useNavigate()
  const { videos } = useContext(UploaderContext)
  const highlights = useMemo(
    () => videos
    // .filter((v) => v.setting.clips)
    // .reduce(
    //   (prev, cur) => [
    //     ...prev,
    //     ...cur.setting.clips
    //       .filter((c) => c.type === '快剪')
    //       .map((cc) => ({
    //         ...cc,
    //         ...cur,
    //       })),
    //   ],
    //   []
    // )
  )

  const handleDelete = async () => {
    // const res = await apiServices.data({
    //   path: `material/${selectedId}`,
    //   method: 'delete',
    // })
    // if (!res.error) setList(list.filter((l) => l.material_id !== selectedId))
    // setselectedId('')
  }

  const [deleteShow, setdeleteShow] = useState(false)
  const handleDeleteClose = (id) => {
    setdeleteShow(false)
    if (id) handleDelete(id)
  }

  const [settingShow, setsettingShow] = useState(false)
  const handleSettingClose = (id) => {
    setsettingShow(false)
    if (id) handleSettingClose(id)
  }

  const [tempSearch, setTempSearch] = useState('')
  const [search, setSearch] = useState('')
  const [focus, setFocus] = useState(false)
  // const [selected, setselected] = useState('')

  // const [showDate, setshowDate] = useState(false)
  // const [date, setdate] = useState({
  //   startDate: new Date(),
  //   endDate: new Date(),
  //   key: 'selection',
  // })
  // const [data, setdata] = useState({
  //   originName: '',
  //   date: '',
  //   type: '路口',
  // })

  // const onDataChange = (e) =>
  //   setdata({ ...data, [e.target.name]: e.target.value })

  const formatTime = (second) => {
    if (!second) return '00:00:00'
    return new Date(parseFloat(second) * 1000).toISOString().substr(11, 8)
  }

  // const [show, setshow] = useState(false)

  return (
    <Container className="d-flex flex-column pt-3 h-100">
      <Row className="px-5">
        <Col xs={2} />
        <Col className="d-flex">
          <h5 className="my-auto text-chelonia-light fw-bold">
            專案成品 / 上傳設定
          </h5>
        </Col>
        {/* <Col xs={2} className="d-flex justifu-content-end">
          <Form.Select
            className="w-100 h-100"
            aria-label="Default select example"
            // onChange={(e) => setselected(e.target.value)}
            // value={selected}
          >
            <option value="" className="d-none">
              選擇部門
            </option>
            {['部門一', '部門二', '部門三'].map((label, i) => (
              <option key={i} value={label}>
                {label}
              </option>
            ))}
          </Form.Select>
        </Col> */}
        <Col xs={5} className="d-flex pe-0">
          <InputGroup className=" ms-auto ">
            <Form.Control
              placeholder="輸入關鍵字以搜尋專案成品..."
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
              value={tempSearch}
              onChange={(event) => setTempSearch(event.target.value)}
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
              onKeyDown={(event) => {
                if (
                  event.key === 'Enter' &&
                  !event.nativeEvent.isComposing &&
                  focus
                )
                  setSearch(tempSearch)
              }}
            />
            <Button
              variant="outline-dark"
              id="button-addon2"
              title="搜 尋"
              onClick={() => setSearch(tempSearch)}
            >
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          </InputGroup>
          <div className="my-auto ms-auto" style={{ width: '42%' }}>
            <Button
              className="d-flex ms-auto"
              variant="danger"
              onClick={() => {
                setsettingShow(true)
              }}
            >
              Youtube 設定&ensp;
              <FontAwesomeIcon icon={faYoutube} className="my-auto" />
            </Button>
          </div>
        </Col>
      </Row>

      <Row
        className="flex-grow-1 pt-3 pb-5 px-5 h-100"
        style={{ overflowY: 'auto', overflowX: 'hidden' }}
      >
        {highlights && highlights.length ? (
          <ListGroup className="pe-0">
            {highlights
              .filter(({ name }) => !search || (name && name.includes(search)))
              .map(({ name, view_url, duration, created_on, user_name }, i) => (
                <ListGroupItem className="d-flex" key={i}>
                  <div style={{ height: '5rem' }}>
                    <video
                      width="150px"
                      height="100%"
                      className="m-auto pe-2"
                      controls
                    >
                      <track kind="captions" />
                      <source src={view_url} />
                    </video>
                  </div>
                  <p
                    className="w-25 my-auto text-start oneLineEllipsis"
                    title={name}
                  >
                    {name}
                  </p>
                  <small className="w-30 my-auto text-start ps-2">
                    <span className="fw-regular text-chelonia">24 MB</span>
                    <br />
                    <span className="fw-regular text-chelonia">長度｜</span>
                    {formatTime(duration)}
                    <br />
                    <span className="fw-regular text-chelonia">建立者｜</span>
                    {user_name}
                    <br />
                    <span className="fw-regular text-chelonia">建立時間｜</span>
                    {moment(created_on).format('yyyy-MM-DD')}
                  </small>
                  <Button
                    className="ms-auto"
                    style={{ boxShadow: 'none' }}
                    variant="edit"
                    onClick={() => {
                      // setselectedId(i)
                      // setshow(true)
                    }}
                    title="編 輯 名 稱"
                    size
                  >
                    <FontAwesomeIcon icon={faPenToSquare} />
                  </Button>
                  <Button
                    style={{ boxShadow: 'none' }}
                    title="Youtube 上 傳"
                    variant="edit"
                  >
                    <FontAwesomeIcon icon={faYoutube} />
                  </Button>
                  <Button
                    style={{ boxShadow: 'none' }}
                    title="下 載"
                    variant="edit"
                  >
                    <FontAwesomeIcon icon={faDownload} />
                  </Button>
                  <Button
                    style={{ boxShadow: 'none' }}
                    variant="red"
                    onClick={() => {
                      // setselectedId(i)
                      setdeleteShow(true)
                    }}
                    title="刪 除"
                  >
                    <FontAwesomeIcon icon={faTrashCan} />
                  </Button>
                </ListGroupItem>
              ))}
          </ListGroup>
        ) : (
          <div className="d-flex ps-3 border">
            <h5 className="m-auto text-chelonia-light">目前尚無資料</h5>
          </div>
        )}
      </Row>
      {/* <YoutubeModal
        setting={{
          show,
        }}
      /> */}
      <DeleteModal
        setting={{
          show: deleteShow,
          name: '',
          handleClose: handleDeleteClose,
        }}
      />
      <SettingModal
        setting={{
          show: settingShow,
          name: '',
          handleClose: handleSettingClose,
        }}
      />
    </Container>
  )
}

SettingModal.propTypes = {
  setting: PropTypes.shape().isRequired,
}

DeleteModal.propTypes = {
  setting: PropTypes.shape().isRequired,
}

AutoComplete.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default Hightlights
