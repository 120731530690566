import React, { useState, useEffect, useMemo, useContext } from 'react'
import PropTypes from 'prop-types'
import { DateRange } from 'react-date-range'
import moment from 'moment'
import {
  faCalendarAlt,
  faCheckCircle,
  faCirclePlay,
  faCirclePlus,
  faCropSimple,
  faDownload,
  faFilm,
  faPlus,
  faScissors,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { faStar } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Container,
  Nav,
  Row,
  Col,
  Form,
  Button,
  FormLabel,
  InputGroup,
  Modal,
  ListGroupItem,
} from 'react-bootstrap'
import apiServices from '../services/apiServices'
import { UploaderContext } from '../components/ContextProvider'

// const subline = [
//   {
//     start: 1.8800000000000008,
//     end: 7.56,
//     text: '',
//   },
// { start: 8.64, end: 9.66, text: '\u9019\u4e00\u7403\u5c31\u662f' },
// {
//   start: 10.620000000000001,
//   end: 12.9,
//   text: '\u9019\u7403\u88ab\u5e72\u64fe\u5230\u524d\u9762\u5feb\u653b\u7684\u6a5f\u6703',
// },
// {
//   start: 13.54,
//   end: 15.18,
//   text: ' \u66fc\u59ae\u9ad8\u76f4\u63a5\u4e0a\u4f86\u88ab\u84cb\u5230',
// },
// {
//   start: 16.16,
//   end: 18.0,
//   text: '\u6797\u66f8\u8c6a\u6536\u5272\u56de\u53bb',
// },
// {
//   start: 19.92,
//   end: 25.32,
//   text: '\u6709\u6642\u5019\u4e00\u76f4\u4e0d\u653e\u68c4\u7684\u8ddf\u4e0a\u4f86\u624d\u662f\u66fc\u59ae\u9ad8',
// },
// {
//   start: 25.76,
//   end: 27.68,
//   text: '\u76f4\u63a5\u8981\u63a5\u5e95\u7dda\u53c8\u662f\u4e00\u500b\u6f02\u4eae\u7684\u64e6\u677f',
// },
// {
//   start: 35.38,
//   end: 39.28,
//   text: '\u4e5f\u76f8\u7576\u96aa\u518d\u904e\u4f86\u7d66\u4ed6\u9b6f\u51a0\u826f\u53ea\u8981\u7d66\u4ed6\u4e00\u9762\u523a\u7834\u4e86',
// },
// {
//   start: 40.06,
//   end: 44.74,
//   text: '\u6797\u66f8\u8c6a\u524d\u9762\u7684\u5feb\u653b\u6a5f\u6703\u9762\u5c0d\u9322\u80af\u5c3c\u98db\u64b2\u7684\u5169\u534a\u9084\u662f\u786c\u6253\u9032\u53bb',
// },
// { start: 45.42, end: 46.64, text: '\u9019\u624d\u662f\u62db\u724c\u554a' },
// {
//   start: 48.42,
//   end: 50.0,
//   text: '\u9322\u80af\u5c3c\u99ac\u4e0a\u60f3\u8981\u63db\u984f\u8272',
// },
// {
//   start: 50.339999999999996,
//   end: 52.28,
//   text: '\u9019\u4e00\u7403\u76f4\u63a5\u88ab\u7c73\u6b47\u723e\u7d66\u64a5\u4e0b\u4f86',
// },
// {
//   start: 52.76,
//   end: 53.8,
//   text: '\u518d\u662f\u4e00\u500b\u5feb\u653b\u6a5f\u6703',
// },
// { start: 54.68, end: 55.68, text: 'OK\u518d\u4ea4\u56de\u4f86' },
// {
//   start: 57.88,
//   end: 59.82,
//   text: '\u4e09\u5206\u7403,\u53c8\u662f\u8c6a\u54e5',
// },
// { start: 73.92, end: 74.96, text: '\u518d\u62c9\u51fa\u4f86,' },
// {
//   start: 75.3,
//   end: 81.14,
//   text: '\u9019\u9846\u5730\u88ab\u63a9\u8b77\u4e4b\u5f8c,\u9019\u4e00\u7403\u6797\u4f5c\u771f\u5f80\u88e1\u982d\u6478\u5169\u53cd\u6295,\u4f86\u4e0d\u53ca\u4e86,\u5df2\u7d93\u5169\u500b\u63a5\u4e0d\u5230',
// },
// { start: 88.44, end: 89.5, text: '\u4e09\u5206\u7403,' },
// {
//   start: 94.88,
//   end: 104.04,
//   text: '\u53c8\u662f\u6797\u4f5c\u6797\u6d77\u598d,\u53c8\u662f\u6797\u4f5c\u51fa\u4f86\u63a5\u7403\u4e2d\u8ddd\u96e2\u7684\u8df3\u6295,\u5169\u5206,\u518d\u6253',
// },
// { start: 104.65, end: 105.26, text: '\u9019\u4e00\u7bc0,' },
// {
//   start: 106.1,
//   end: 122.84,
//   text: '\u5b8c\u5168\u7121\u89e3\u554a\u4e09\u5206\u7403,\u53c8\u662f\u6797\u6d77\u598d\u4e2d\u8ddd\u96e2\u7684\u8df3\u6295,\u5169\u5206,\u518d\u6253\u4e2d\u8ddd\u96e2\u7684\u8df3\u6295,\u5169\u5206,',
// },
// { start: 125.14, end: 125.14, text: '\u518d\u6253\u4e09\u5206\u7403,' },
// {
//   start: 125.6,
//   end: 127.36,
//   text: '\u53c8\u662f\u6797\u6d77\u598d\u4e2d\u8ddd\u96e2\u7684\u8df3\u6295,',
// },
// { start: 134.26, end: 134.26, text: '\u5169\u5206,\u518d\u6253' },
// {
//   start: 135.04,
//   end: 154.74,
//   text: '\u4e09\u5206\u7403,\u53c8\u662f\u6797\u6d77\u598d\u4e2d\u8ddd\u96e2\u7684\u8df3\u6295,\u5169\u5206,\u518d\u6253\u4e09\u5206\u7403,\u53c8\u662f\u6797\u6d77\u598d\u4e09\u5206\u7403,',
// },
// {
//   start: 155.08,
//   end: 158.92,
//   text: '\u53c8\u662f\u6797\u6d77\u598d\u4e09\u5206\u7403,\u53c8\u662f\u6797\u6d77\u598d\u4e2d\u8ddd\u96e2\u7684\u8df3\u6295,',
// },
// {
//   start: 159.3,
//   end: 160.74,
//   text: '\u5169\u5206,\u518d\u6253\u4e09\u5206\u7403,\u53c8\u662f\u6797\u6d77\u598d\u4e2d\u8ddd\u96e2\u7684\u8df3\u6295,',
// },
// { start: 164.48, end: 167.6, text: '\u5169\u5206,\u518d\u6253\u554a' },
// {
//   start: 169.01999999999998,
//   end: 174.28,
//   text: '\u554a\u554a\u554a\u554a\u554a\u554a\u554a\u554a\u554a\u554a',
// },
// {
//   start: 175.85999999999999,
//   end: 186.22,
//   text: '\u554a\u554a\u554a\u554a\u554a\u554a\u554a\u554a\u554a\u554a\u554a',
// },
// ]

function ReplayModal({ setting }) {
  const { title, show, handleClose, src } = setting
  // console.log(subline)
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header className="h5" closeButton>
        {title}
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={3} className="ms-auto">
            <Button
              className="ms-auto my-auto"
              style={{ boxShadow: 'none' }}
              title="下 載"
              variant="edit"
            >
              下載&ensp;
              <FontAwesomeIcon icon={faDownload} />
            </Button>
          </Col>
        </Row>
        <Row>
          <video width="150px" height="100%" className="m-auto pe-2" controls>
            <track kind="captions" />
            <source src={src} />
          </video>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

function AutoComplete({ setting }) {
  const { text, options, placeholder, show, onFocus, handleSelect } = setting
  const [search, setsearch] = useState('')
  const selected = useMemo(
    () => options.filter(({ checked }) => checked),
    [options]
  )

  return (
    <div
      className="w-100 pe-3 position-relative mb-2"
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
      aria-hidden
    >
      <div className="d-flex flex-nowrap w-100">
        <div className="d-flex px-2">
          <Form.Label className="my-auto text-nowrap">{text}</Form.Label>
        </div>
        <div className="flex-fill">
          <div className="input-container">
            {selected.map((s) => (
              <div className="input-tag d-flex">
                <span>{s.label}</span>
                <FontAwesomeIcon
                  className="ms-2 fs-7 my-auto"
                  style={{
                    cursor: 'pointer',
                  }}
                  icon={faTimes}
                  onClick={() => handleSelect(s.value)}
                />
              </div>
            ))}
            <Form.Control
              className="p-0"
              onFocus={onFocus}
              placeholder={show || selected.length ? '' : placeholder}
            />
          </div>
        </div>
      </div>
      <div
        className="position-absolute w-100 border"
        style={{
          top: '100%',
          height: '500px',
          display: show ? 'block' : 'none',
          zIndex: '999999',
          backgroundColor: 'white',
        }}
      >
        <div className="mb-2 p-2 w-100 d-flex flex-column">
          <InputGroup
            style={{ borderRadius: '.25rem' }}
            className="border rounded-lg"
            size="sm"
          >
            <Form.Control
              type="text"
              placeholder="Search..."
              aria-label="Search..."
              aria-describedby="btnGroupAddon"
              title="輸 入 關 鍵 字 搜 尋"
              value={search}
              onChange={(e) => setsearch(e.target.value)}
            />
          </InputGroup>
          <hr />
          <div
            className="scrollbarShow"
            style={{ maxHeight: '25rem', overflowY: 'auto' }}
          >
            {options
              .filter(({ label }) => !search || label.includes(search))
              .map(({ label, value, checked }) => (
                <>
                  <ListGroupItem
                    title={label}
                    className="px-3 py-0 d-flex"
                    key={value}
                    href=""
                    onClick={() => handleSelect(value)}
                  >
                    <span>{label}</span>
                    {checked && (
                      <FontAwesomeIcon
                        className="ms-auto my-auto"
                        icon={faCheckCircle}
                      />
                    )}
                  </ListGroupItem>
                  <hr />
                </>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

function ProjectModal({ setting }) {
  const { show, form, setDuration, defaultValue = {}, handleClose } = setting
  const [showDate, setshowDate] = useState(false)

  const [data, setdata] = useState({})
  const onDataChange = (e) =>
    setdata({ ...data, [e.target.name]: e.target.value })

  useEffect(() => {
    if (show) {
      setshowDate(false)
      setdata(
        form.reduce(
          (prev, cur) => ({
            ...prev,
            [cur.name]: defaultValue.setting
              ? defaultValue.setting[cur.name]
              : '',
          }),
          {}
        )
      )
    }
  }, [show, defaultValue])
  const [date, setdate] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  })
  return (
    <Modal
      style={{ zIndex: '1501' }}
      show={show}
      onHide={() => handleClose()}
      className="py-2 px-4"
    >
      <Modal.Header className="h5" closeButton>
        {defaultValue.setting ? `編輯影片` : `新建影片`}
      </Modal.Header>
      <Modal.Body className="p-4">
        {form.map((f, i) => {
          switch (f.type) {
            case 'date':
              return (
                <React.Fragment key={i}>
                  <Form.Label className="mb-1 mt-3 fw-bold text-chelonia">
                    {f.label}
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      name={f.name}
                      type="text"
                      value={data[f.name] || f.placeholder}
                      placeholder={f.placeholder}
                      onFocus={() => setshowDate(!showDate)}
                      readOnly
                    />
                    <div
                      style={{
                        height: showDate ? '100%' : '0%',
                        transition: 'height .3s ease-in',
                        position: 'absolute',
                        left: '-50',
                      }}
                    >
                      {showDate && (
                        <DateRange
                          ranges={[date]}
                          editableDateInputs
                          onChange={({ selection }) => {
                            setdate(selection)
                            onDataChange({
                              target: {
                                name: 'date',
                                value: `${moment(selection.startDate).format(
                                  'yyyy-MM-DD'
                                )}-${moment(selection.endDate).format(
                                  'yyyy-MM-DD'
                                )}`,
                              },
                            })
                          }}
                          moveRangeOnFirstSelection={false}
                        />
                      )}
                    </div>
                    <Button
                      variant="chelonia2"
                      onClick={() => setshowDate(!showDate)}
                    >
                      確認
                    </Button>
                  </InputGroup>
                </React.Fragment>
              )
            case 'select':
              return (
                <React.Fragment key={i}>
                  <Form.Label className="mb-1 mt-3 fw-bold text-chelonia">
                    {f.label}
                  </Form.Label>

                  <Form.Select
                    name={f.name}
                    type={f.type}
                    value={data[f.name] || ''}
                    onChange={onDataChange}
                    placeholder={f.placeholder}
                    onFocus={() => setshowDate(false)}
                  >
                    <option value="">未選擇</option>
                    {f.content.map((c) => (
                      <option value={c}>{c}</option>
                    ))}
                  </Form.Select>
                </React.Fragment>
              )
            case 'file':
              return (
                <React.Fragment key={i}>
                  <Form.Label className="mb-1 mt-3 fw-bold text-chelonia">
                    {f.label}
                  </Form.Label>

                  <Form.Control
                    name={f.name}
                    type={f.type}
                    onChange={async (e) => {
                      const formData = new FormData()
                      formData.append('file', e.target.files[0])
                      const temp = URL.createObjectURL(e.target.files[0])
                      const audio = document.createElement('audio')
                      audio.muted = true
                      const source = document.createElement('source')
                      source.src = temp // --> blob URL
                      audio.preload = 'metadata'
                      audio.appendChild(source)
                      audio.onloadedmetadata = () => {
                        setDuration(audio.duration)
                      }
                      const uploaded = await apiServices.data({
                        path: `material/file`,
                        method: 'post',
                        data: formData,
                        contentType: 'multipart/form-data',
                      })
                      onDataChange({
                        target: {
                          name: f.name,
                          value: uploaded,
                        },
                      })
                    }}
                    placeholder={f.placeholder}
                    onFocus={() => setshowDate(false)}
                  />
                </React.Fragment>
              )
            default:
              return (
                <React.Fragment key={i}>
                  <Form.Label className="mb-1 mt-3 fw-bold text-chelonia">
                    {f.label}
                  </Form.Label>

                  <Form.Control
                    name={f.name}
                    type={f.type}
                    value={data[f.name] || ''}
                    onChange={onDataChange}
                    placeholder={f.placeholder}
                    onFocus={() => setshowDate(false)}
                  />
                </React.Fragment>
              )
          }
        })}
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button
          className="ms-auto"
          style={{ boxShadow: 'none' }}
          variant="secondary"
          onClick={() => handleClose()}
        >
          取 消
        </Button>
        <Button
          className="me-auto"
          style={{ boxShadow: 'none' }}
          variant="chelonia-dark"
          onClick={() => handleClose(data)}
        >
          確 認
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

function Historical() {
  const { videos } = useContext(UploaderContext)
  const highlights = useMemo(
    () => videos
    // .filter((v) => v.setting.clips)
    // .reduce(
    //   (prev, cur) => [
    //     ...prev,
    //     ...cur.setting.clips
    //       .filter((c) => c.type === '快剪')
    //       .map((cc) => ({
    //         ...cc,
    //         ...cur,
    //       })),
    //   ],
    //   []
    // )
  )

  const [showDate, setshowDate] = useState(false)
  const [date, setdate] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  })
  const [data, setdata] = useState({
    originName: '',
    date: '',
    type: '',
  })

  const onDataChange = (e) =>
    setdata({ ...data, [e.target.name]: e.target.value })

  const initOptions = {
    channel: [
      { label: 'tag1', value: 'tag1' },
      { label: 'tag2', value: 'tag2' },
      { label: 'tag3', value: 'tag3' },
    ],
    activity: [
      { label: 'tag1', value: 'tag1' },
      { label: 'tag2', value: 'tag2' },
      { label: 'tag3', value: 'tag3' },
    ],
    event: [
      { label: 'tag1', value: 'tag1' },
      { label: 'tag2', value: 'tag2' },
      { label: 'tag3', value: 'tag3' },
    ],
    people: [
      { label: 'tag1', value: 'tag1' },
      { label: 'tag2', value: 'tag2' },
      { label: 'tag3', value: 'tag3' },
    ],
    tags: [
      { label: 'tag1', value: 'tag1' },
      { label: 'tag2', value: 'tag2' },
      { label: 'tag3', value: 'tag3' },
    ],
    clip: [
      { label: 'tag1', value: 'tag1' },
      { label: 'tag2', value: 'tag2' },
      { label: 'tag3', value: 'tag3' },
    ],
  }
  const [fakeOptions, setfakeOptions] = useState(initOptions)
  const handleSelect = (key, e) => {
    setfakeOptions({
      ...fakeOptions,
      [key]: fakeOptions[key].map(({ label, value, checked }) =>
        value === e
          ? { label, value, checked: !checked }
          : { label, value, checked }
      ),
    })
  }

  const initFocus = {
    channel: false,
    activity: false,
    event: false,
    people: false,
    tags: false,
    clip: false,
  }
  const [focus, setfocus] = useState(initFocus)

  const [replaySetting, setreplaySetting] = useState({
    show: false,
    src: '',
  })

  return (
    <Container
      fluid
      className="d-flex flex-column px-4"
      onClick={() => setfocus(initFocus)}
    >
      <Nav>
        <div className="pt-2 nav nav-tabs" id="nav-tab" role="tablist">
          <button
            className="nav-link active"
            id="nav-home-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-home"
            type="button"
            role="tab"
            aria-controls="nav-home"
            aria-selected="true"
          >
            搜尋
          </button>
          <button
            className="nav-link"
            id="nav-profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-profile"
            type="button"
            role="tab"
            aria-controls="nav-profile"
            aria-selected="false"
          >
            剪輯 (0)
          </button>
        </div>
      </Nav>

      <div
        className="tab-content"
        id="nav-tabContent"
        style={{ height: '100%' }}
      >
        <div
          className="tab-pane fade show active py-3"
          id="nav-home"
          role="tabpanel"
          aria-labelledby="nav-home-tab"
        >
          <Row>
            <Col>
              <AutoComplete
                setting={{
                  text: '節目',
                  placeholder: '節目多選輸入框(多選)...',
                  options: fakeOptions.channel,
                  handleSelect: (e) => handleSelect('channel', e),
                  show: focus.channel,
                  onFocus: () =>
                    setfocus({
                      ...focus,
                      channel: true,
                    }),
                }}
              />
              <AutoComplete
                setting={{
                  text: '活動',
                  placeholder: '活動輸入框(多選)...',
                  options: fakeOptions.activity,
                  handleSelect: (e) => handleSelect('activity', e),
                  show: focus.activity,
                  onFocus: () =>
                    setfocus({
                      ...focus,
                      activity: true,
                    }),
                }}
              />
            </Col>
            <Col>
              <InputGroup>
                <Form.Label className="px-2 my-auto">日期</Form.Label>
                <Form.Control
                  // name={f.name}
                  value={data.date || ''}
                  // placeholder={f.placeholder}
                  type="text"
                  onFocus={() => setshowDate(!showDate)}
                  readOnly
                />
                <div
                  style={{
                    height: showDate ? '100%' : '0%',
                    transition: 'height .3s ease-in',
                    position: 'absolute',
                    zIndex: '999',
                    top: '100%',
                  }}
                >
                  {showDate && (
                    <DateRange
                      ranges={[date]}
                      editableDateInputs
                      onChange={({ selection }) => {
                        setdate(selection)
                        onDataChange({
                          target: {
                            name: 'date',
                            value: `${moment(selection.startDate).format(
                              'yyyy-MM-DD'
                            )}-${moment(selection.endDate).format(
                              'yyyy-MM-DD'
                            )}`,
                          },
                        })
                      }}
                      moveRangeOnFirstSelection={false}
                    />
                  )}
                </div>
                <Button
                  variant="outline-dark"
                  onClick={() => setshowDate(!showDate)}
                >
                  <FontAwesomeIcon icon={faCalendarAlt} />
                </Button>
              </InputGroup>
            </Col>
            <Col className="my-auto">
              <InputGroup className="my-auto">
                <div key="only" className="my-auto text-chelonia-light fw-bold">
                  <Form.Check type="checkbox" id="only" label="ONLY" />
                </div>
              </InputGroup>
            </Col>
          </Row>
          <hr className="mt-1 mb-2" />
          <Row>
            <Col>
              {/* <InputGroup className="mb-2">
                <Form.Label className="px-2 my-auto">事件</Form.Label>
                <Form.Control readOnly type="text" placeholder="" />
              </InputGroup> */}
              <AutoComplete
                setting={{
                  text: '事件',
                  placeholder: '事件(多選)...',
                  options: fakeOptions.event,
                  handleSelect: (e) => handleSelect('event', e),
                  show: focus.event,
                  onFocus: () =>
                    setfocus({
                      ...focus,
                      event: true,
                    }),
                }}
              />
            </Col>
            <Col>
              {/* <InputGroup className="mb-2">
                <Form.Label className="px-2 my-auto">人物</Form.Label>
                <Form.Control readOnly type="text" placeholder="" />
              </InputGroup> */}
              <AutoComplete
                setting={{
                  text: '人物',
                  placeholder: '人物(多選)...',
                  options: fakeOptions.people,
                  handleSelect: (e) => handleSelect('people', e),
                  show: focus.people,
                  onFocus: () =>
                    setfocus({
                      ...focus,
                      people: true,
                    }),
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {/* <InputGroup className="mb-2">
                <Form.Label className="px-2 my-auto">Tags</Form.Label>
                <Form.Control readOnly type="text" placeholder="" />
              </InputGroup> */}
              <AutoComplete
                setting={{
                  text: 'Tags',
                  placeholder: 'Tags...',
                  options: fakeOptions.tags,
                  handleSelect: (e) => handleSelect('tags', e),
                  show: focus.tags,
                  onFocus: () =>
                    setfocus({
                      ...focus,
                      tags: true,
                    }),
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <InputGroup className="mb-2">
                <Form.Label className="px-2 my-auto">片段</Form.Label>
                <Form.Control type="text" placeholder="搜尋片段名稱" />
              </InputGroup>
              {/* <AutoComplete
                setting={{
                  text: '片段',
                  placeholder: '片段...',
                  options: fakeOptions.clip,
                  handleSelect: (e) => handleSelect('clip', e),
                  show: focus.clip,
                  onFocus: () =>
                    setfocus({
                      ...focus,
                      clip: true,
                    }),
                }}
              /> */}
            </Col>
          </Row>
          <Row>
            <Col xs={10} />
            <Col xs={2} className="text-end pb-2">
              <Button size="sm" className="ms-auto" variant="warning">
                <FontAwesomeIcon icon={faPlus} /> 全部加入
              </Button>
            </Col>
          </Row>

          <div>
            <Row>
              <Col xs={1} />
              <Col xs={1}>Date</Col>
              <Col xs={3}>活動</Col>
              <Col xs={1}>人物</Col>
              <Col xs={4}>片段名稱</Col>
              <Col xs={2}>功能</Col>
            </Row>
            {highlights.map((h) => (
              <Row
                className="border-top border-bottom"
                style={{
                  height: '17vh',
                }}
              >
                <Col className="d-flex h-100" xs={1}>
                  <FontAwesomeIcon
                    className="m-auto me-2 text-icon"
                    icon={faPlus}
                  />
                  <FontAwesomeIcon
                    className="m-auto text-icon2"
                    icon={faStar}
                  />
                </Col>
                <Col className="d-flex h-100" xs={1}>
                  <span className="m-auto">
                    {moment(h.created_on).format('yyyy-MM-DD')}
                  </span>
                </Col>
                <Col className="d-flex h-100" xs={3}>
                  <span className="my-auto text-start oneLineEllipsis px-0">
                    {h.name}
                  </span>
                </Col>
                <Col className="d-flex h-100" xs={1}>
                  <span className="m-auto px-0">AI字幕</span>
                </Col>
                <Col className="d-flex h-100 py-2" xs={4}>
                  <span
                    className="my-auto h-100 text-start"
                    style={{
                      overflowY: 'scroll',
                      overflowX: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {/* {h.setting.subline
                      ? h.setting.subline.map(({ text }) => text).join()
                      : '(無字幕)'} */}
                  </span>
                </Col>
                <Col className="d-flex h-100" xs={2}>
                  <Button
                    className="m-auto text-nowrap"
                    style={{ boxShadow: 'none' }}
                    title="Replay"
                    variant="edit"
                    onClick={() =>
                      setreplaySetting({
                        ...replaySetting,
                        show: true,
                        title: h.name,
                        src: h.setting.uploadedVideo,
                      })
                    }
                  >
                    <FontAwesomeIcon icon={faCirclePlay} />
                  </Button>
                  <Button
                    className="m-auto text-nowrap"
                    style={{ boxShadow: 'none' }}
                    title="下 載"
                    variant="edit"
                  >
                    <FontAwesomeIcon icon={faDownload} />
                  </Button>
                  <Button
                    className="m-auto text-nowrap"
                    style={{ boxShadow: 'none' }}
                    title="剪 輯"
                    variant="edit"
                  >
                    <FontAwesomeIcon icon={faCropSimple} />
                  </Button>
                  <Button
                    className="m-auto text-nowrap"
                    style={{ boxShadow: 'none' }}
                    title="加 入"
                    variant="edit"
                  >
                    <FontAwesomeIcon icon={faCirclePlus} />
                  </Button>
                </Col>
              </Row>
            ))}
          </div>
        </div>

        <div
          className="tab-pane fade"
          style={{ height: '100%' }}
          id="nav-profile"
          role="tabpanel"
          aria-labelledby="nav-profile-tab"
        >
          <div className="h-100 w-100">
            {/* <Row className="d-flex p-3 pt-2 pb-0">
              <Col
                xs={12}
                className="fw-bold text-start text-chelonia-light my-auto pe-0"
              >
                影片剪輯
              </Col>
            </Row>
            <hr className="mx-2 mt-2 mb-1" /> */}
            <Row className="d-flex px-3 py-1">
              <Col xs={8} />
              <Col xs={4} className="d-flex ps-0">
                <Form.Select
                  name="size"
                  className="w-100 h-100"
                  aria-label="Default select example"
                  // onChange={handleSelected}
                  // value={selected.size}
                >
                  <option value="" className="d-none">
                    影片比例
                  </option>
                  {['Horizontal (16:9)'].map((label, i) => (
                    <option key={i} value={label}>
                      {label}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Row>
            <hr className="mx-2 mt-2 mb-1" />
            <Row style={{ height: '21%' }}>
              <div className="h-100 w-100 border rounded bg-light">
                <FormLabel
                  htmlFor="file"
                  className="d-flex h-100 w-100"
                  style={{ cursor: 'pointer' }}
                  title="影 片 上 傳"
                >
                  <FontAwesomeIcon
                    icon={faFilm}
                    className="text-grey h-25 m-auto"
                  />
                </FormLabel>
              </div>
            </Row>

            <Row className="d-flex px-4 py-1">
              <Button
                size="sm"
                className="mt-1 w-32"
                variant="outline-secondary"
                style={{ right: '0%', top: '0%' }}
                // onClick={() =>
                //   setMaterial({
                //     show: true,
                //     type: '轉場動畫',
                //     handleClose: (value) => {
                //       if (value) handleAddMaterial(value)
                //       setMaterial({
                //         show: false,
                //       })
                //     },
                //   })
                // }
              >
                加入轉場動畫&ensp;
                <FontAwesomeIcon icon={faCirclePlus} />
              </Button>
              <Button
                size="sm"
                className="mt-1 w-32 mx-auto"
                variant="outline-secondary"
                style={{ right: '0%', top: '0%' }}
                // onClick={() => setshow(true)}
              >
                選擇浮水印&ensp;
                <FontAwesomeIcon icon={faCirclePlus} />
              </Button>
              <Button
                size="sm"
                className="mt-1 w-32"
                variant="outline-secondary"
                style={{ right: '0%', top: '0%' }}
                // onClick={() => setshow(true)}
              >
                手動加入片段&ensp;
                <FontAwesomeIcon icon={faCirclePlus} />
              </Button>
            </Row>
            <Row className="d-flex p-3 py-2" />
            <Row className="d-flex px-3 py-2">
              <Button
                size="md"
                className="mt-0 ms-auto w-100"
                variant="danger"
                style={{ right: '0%', top: '0%' }}
                // onClick={handleBindClips}
              >
                <FontAwesomeIcon icon={faScissors} />
                &ensp; 影片快剪&ensp; (總長: 00:00:00)
              </Button>
            </Row>
          </div>
        </div>
      </div>
      <ReplayModal
        setting={{
          ...replaySetting,
          handleClose: () =>
            setreplaySetting({
              ...replaySetting,
              show: false,
            }),
        }}
      />
    </Container>
  )
}

ProjectModal.propTypes = {
  setting: PropTypes.shape().isRequired,
}

AutoComplete.propTypes = {
  setting: PropTypes.shape().isRequired,
}

ReplayModal.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default Historical
