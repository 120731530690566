import React, { useContext } from 'react'
import { Row, Col, ProgressBar } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCloudArrowUp,
  faXmark,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons'
import { UploaderContext } from './ContextProvider'

function UploadHelper() {
  const { show, setShow, files } = useContext(UploaderContext)
  console.log(files)

  return (
    <div
      className="position-absolute w-35 d-flex flex-column justify-content-end px-5 py-4"
      style={{
        filter: 'drop-shadow(rgba(0, 42, 76, 0.1) 2px 2px 5px)',
        right: 0,
        bottom: 0,
        zIndex: '9999',
        pointerEvents: show ? 'auto' : 'none',
      }}
    >
      <Row
        className="h-90 border d-flex flex-column p-3 bg-light rounded"
        style={{
          visibility: show ? 'visible' : 'hidden',
          minWidth: '60vw',
          maxWidth: '60vw',
          minHeight: '50vh',
          maxHeight: '50vh',
        }}
      >
        <div
          className="d-flex h-6"
          style={{
            height: '5vh',
          }}
        >
          <span className="mx-auto fw-bolder text-chelonia h7">上傳小幫手</span>
          <div
            className="position-absolute fs-6"
            style={{ top: '8.5%', right: '7%', cursor: 'pointer' }}
            onClick={() => setShow(!show)}
            aria-hidden
          >
            <FontAwesomeIcon icon={faXmark} title="收合" />
          </div>
        </div>
        <div
          style={{
            height: '5vh',
            maxHeight: '5vh',
          }}
        >
          <Row>
            <Col xs={2}>檔案名稱</Col>
            <Col xs={2}>類型</Col>
            <Col>進度</Col>
            <Col xs={2}>狀態</Col>
          </Row>
          <hr className="mt-1 mb-2" />
        </div>
        <div
          className="overflow-scroll d-flex flex-column"
          style={{
            height: '34vh',
            maxHeight: '34vh',
          }}
        >
          {/* TODO: UPLOADING STATUS */}
          {files.map((file, index) => {
            const { name, status, type, progress = 0 } = file
            return (
              <>
                <Row key={index}>
                  <Col className="d-flex" xs={2}>
                    <p className="my-auto oneLineEllipsis" title={name}>
                      {name}
                    </p>
                  </Col>
                  <Col className="d-flex" xs={2}>
                    <p className="m-auto">{type}</p>
                  </Col>
                  <Col>
                    {/* <ProgressBar
                      className="rounded-pill fs-6 my-2 mx-auto w-100"
                      style={{ height: '20px' }}
                    >
                      <ProgressBar
                        animated
                        style={{
                          backgroundColor: '#0a004f',
                        }}
                        now={50}
                        label={`${parseInt(50, 10)}%`}
                      />
                      <ProgressBar
                        now={100 - 50}
                        style={{
                          backgroundColor: '#e2e4e8',
                        }}
                      />
                    </ProgressBar> */}
                    {status !== 'done' ? (
                      // <Spinner size="sm" />
                      <ProgressBar
                        className="rounded-pill fs-6 my-2 mx-auto w-100"
                        style={{ height: '20px' }}
                      >
                        <ProgressBar
                          animated
                          style={{
                            backgroundColor: '#0a004f',
                          }}
                          now={progress * 100}
                          label={`${parseInt(progress * 100, 10)}%`}
                        />
                        <ProgressBar
                          now={100 - progress * 100}
                          style={{
                            backgroundColor: '#e2e4e8',
                          }}
                        />
                      </ProgressBar>
                    ) : (
                      <FontAwesomeIcon
                        className="text-success"
                        icon={faCheckCircle}
                      />
                    )}
                  </Col>
                  <Col className="d-flex" xs={2}>
                    <p className="m-auto">{status}</p>
                  </Col>
                </Row>
                <hr />
              </>
            )
          })}
        </div>
      </Row>
      <Row className="h-10 d-flex ms-auto">
        <div
          title="上傳小幫手"
          className="d-flex rounded-circle"
          style={{
            cursor: 'pointer',
            filter: 'drop-shadow(8px 8px 8px rgba(0 42 76 / 70%)',
            backgroundColor: '#fff',
            height: '4rem',
            width: '4rem',
          }}
        >
          <FontAwesomeIcon
            icon={faCloudArrowUp}
            style={{
              zIndex: '555',
              height: '2.25rem',
              width: '2.25rem',
              pointerEvents: 'auto',
              color: 'rgba(0 42 76)',
            }}
            className="m-auto fs-1"
            onClick={() => setShow(!show)}
            // title={show ? '隱 藏' : '上 傳 小 幫 手'}
          />
        </div>
        <i
          className="loader-dot mx-auto mt-auto"
          style={{ bottom: '20%', right: '-12%' }}
        />
      </Row>
    </div>
  )
}

export default UploadHelper
