/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-param-reassign */
import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
// import moment from 'moment'
import Peaks from 'peaks.js'
import TimeRange from 'react-video-timelines-slider'
import { set, format } from 'date-fns'
import moment from 'moment'
import apiServices from '../services/apiServices'

const now = new Date()
const getTodayAtSpecificHour = (hour = 12) =>
  set(now, { hours: hour, minutes: 0, seconds: 0, milliseconds: 0 })

const startTime = getTodayAtSpecificHour(0)
const endTime = getTodayAtSpecificHour(20)

const disabledIntervals = [
  //   { start: getTodayAtSpecificHour(16), end: getTodayAtSpecificHour(17) },
  //   { start: getTodayAtSpecificHour(7), end: getTodayAtSpecificHour(12) },
  //   { start: getTodayAtSpecificHour(20), end: getTodayAtSpecificHour(24) },
]

function MouseTooltip({ setting }) {
  const { style, newTime } = setting
  return (
    <div className="position-absolute" style={style}>
      {newTime}
    </div>
  )
}

function AudioVisualizer({ setting }) {
  const { show, audioUrl, audioElmRef, selectedInterval, setSelectedInterval } =
    setting

  const [videoData, setvideoData] = useState()

  const overviewElmRef = useRef(null)

  const init = () => {
    const options = {
      //   zoomview: {
      //     container: zoomviewElmRef.current,
      //   },
      overview: {
        container: overviewElmRef.current,
      },
      mediaElement: audioElmRef.current,
      mediaUrl: audioUrl,
      webAudio: {
        // audioContext,
        // scale: 128,
        // multiChannel: false,
        audioBuffer: videoData,
      },
    }

    Peaks.init(options, (err, peaks) => {
      if (err) {
        console.error(`Failed to initialize Peaks instance: ${err.message}`)
      }
      console.log(peaks)
      // Do something when the waveform is displayed and ready
    })
  }
  useEffect(() => {
    const getData = async () => {
      const res = await apiServices.extenal({
        url: audioUrl,
        method: 'get',
        responseType: 'arraybuffer',
      })
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)()
      try {
        const audioBuffer = await audioContext.decodeAudioData(res.data)
        setvideoData(audioBuffer)
      } catch (e) {
        console.log('This video has no audio data!')
      }
    }
    if (audioUrl) getData()
  }, [audioUrl])

  useEffect(() => {
    if (videoData && show) init()
  }, [videoData, show])

  const [error, seterror] = useState({
    error: false,
    time: [],
  })
  const [newTime, setnewTime] = useState('')

  const formatTime = (second) => {
    if (!second) return '00:00:00'
    return new Date(parseFloat(second) * 1000).toISOString().substr(11, 8)
  }

  const errorHandler = (e) => {
    const { duration } = audioElmRef.current
    if (!moment(e.time[0]).isSame(moment(error.time[0]))) {
      const max = 3600 * 20
      const t = parseInt(
        duration *
          (moment(e.time[0]).diff(moment().startOf('day'), 'seconds') / max),
        10
      )
      setnewTime(formatTime(t))
    } else if (!moment(e.time[1]).isSame(moment(error.time[1]))) {
      const max = 3600 * 20
      const t = parseInt(
        duration *
          (moment(e.time[1]).diff(moment().startOf('day'), 'seconds') / max),
        10
      )
      setnewTime(formatTime(t))
    }
    seterror(e)
  }

  const onChangeCallback = (i) => {
    setTimeout(() => setnewTime(''), 100)
    setSelectedInterval(i)
  }

  const [toolTip, settoolTip] = useState({
    show: false,
    left: '',
    top: '',
    zIndex: '999',
  })

  return (
    <div
      className="position-relative h-100 w-100"
      style={{
        backgroundColor: 'transparent',
      }}
      // onMouseEnter={}
      aria-hidden
      onMouseMove={(e) => {
        const target = e.currentTarget.getBoundingClientRect()
        const left = e.clientX - target.x - 20
        const top = e.clientY - target.y - 25
        settoolTip({
          ...toolTip,
          left,
          top,
        })
      }}
      // onMouseLeave={}
    >
      {/* <div className="w-100 h-75 bg-black">
        <video
          width="auto"
          height="100%"
          className="m-auto"
          controls
          ref={audioElmRef}
          crossOrigin="anonymous"
        >
          <track kind="captions" />
          <source src={audioUrl} />
        </video>
      </div> */}
      <div
        className="h-100 w-100 position-absolute"
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          zIndex: '1',
          bottom: 0,
        }}
      >
        <div className="w-100 h-100" ref={overviewElmRef} />
      </div>
      <div
        className="h-100 w-100 position-absolute"
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          zIndex: '2',
          bottom: 0,
        }}
      >
        <TimeRange
          error={error}
          ticksNumber={0}
          step={1}
          selectedInterval={selectedInterval}
          timelineInterval={[startTime, endTime]}
          onUpdateCallback={errorHandler}
          onChangeCallback={onChangeCallback}
          disabledIntervals={disabledIntervals}
          formatTick={(ms) => format(new Date(ms), 'HH:mm:ss')}
          formatTooltip={(ms) => format(new Date(ms), 'HH:mm:ss.SSS')}
          showTooltip
          showTimelineError={false}
        />
      </div>
      <MouseTooltip
        setting={{
          style: toolTip,
          newTime,
        }}
      />
    </div>
  )
}

// WaveForm.propTypes = {
//   setting: PropTypes.shape().isRequired,
// }

AudioVisualizer.propTypes = {
  setting: PropTypes.shape().isRequired,
}

MouseTooltip.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default AudioVisualizer
