/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, useMemo, useContext } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleExclamation,
  faCirclePlus,
  faSearch,
  faTimes,
  faTrashCan,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import { DateRange } from 'react-date-range'
import {
  Row,
  Col,
  Button,
  ListGroup,
  ListGroupItem,
  Form,
  Modal,
  InputGroup,
  Container,
  // Image,
} from 'react-bootstrap'
import { AuthContext, ToastContext } from './ContextProvider'
import ClipEndModal from './ClipEndModal'
import apiServices from '../services/apiServices'

// const getCookie = (cname) => {
//   const name = `${cname}=`
//   const decodedCookie = decodeURIComponent(document.cookie)
//   const ca = decodedCookie.split(';')
//   for (let i = 0; i < ca.length; i += 1) {
//     let c = ca[i]
//     while (c.charAt(0) === ' ') {
//       c = c.substring(1)
//     }
//     if (c.indexOf(name) === 0) {
//       const cookie = c.substring(name.length, c.length)
//       if (cookie === '') return false
//       return cookie
//     }
//   }
//   return false
// }

function DeleteModal({ setting }) {
  const { show, name, handleClose } = setting

  return (
    <Modal
      style={{ zIndex: '1501' }}
      show={show}
      onHide={() => handleClose()}
      className="py-2 px-4"
    >
      <Modal.Header closeButton />
      <Modal.Body className="p-4">
        <div className="d-flex">
          <FontAwesomeIcon
            className="px-0 m-auto text-chelonia text-center"
            style={{
              height: '100px',
            }}
            icon={faCircleExclamation}
          />
        </div>
        <h5 className="text-center lh-lg text-chelonia">
          <br />
          警告！刪除後無法復原！
          <br />
          仍要刪除
          <span className="text-danger">{`「${name}」使用者`}</span>
          嗎？
        </h5>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button
          className="ms-auto me-2"
          style={{ boxShadow: 'none' }}
          variant="secondary"
          onClick={() => handleClose()}
        >
          取 消
        </Button>
        <Button
          className="me-auto"
          style={{ boxShadow: 'none' }}
          variant="chelonia-dark"
          onClick={() => handleClose(true)}
        >
          確 認
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

function ProjectModal({ setting }) {
  const {
    show,
    form,
    defaultValue = {},
    setDuration,
    handleClose,
    data,
    onDataChange,
  } = setting
  const [showDate, setshowDate] = useState(false)

  const [date, setdate] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  })
  return (
    <Modal
      style={{ zIndex: '1501' }}
      show={show}
      onHide={() => handleClose()}
      className="py-2 px-4"
    >
      <Modal.Header closeButton>
        {defaultValue.setting ? `編輯使用者` : `新建使用者`}
      </Modal.Header>
      <Modal.Body className="p-4">
        {form.map((f, i) => {
          switch (f.type) {
            case 'date':
              return (
                <React.Fragment key={i}>
                  <Form.Label className="mb-1 mt-3 fw-bold text-chelonia">
                    {f.label}
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      name={f.name}
                      type="text"
                      value={data[f.name] || f.placeholder}
                      placeholder={f.placeholder}
                      onFocus={() => setshowDate(!showDate)}
                      readOnly
                    />
                    <div
                      style={{
                        height: showDate ? '100%' : '0%',
                        transition: 'height .3s ease-in',
                        position: 'absolute',
                        left: '-50',
                      }}
                    >
                      {showDate && (
                        <DateRange
                          ranges={[date]}
                          editableDateInputs
                          onChange={({ selection }) => {
                            setdate(selection)
                            onDataChange({
                              target: {
                                name: 'date',
                                value: `${moment(selection.startDate).format(
                                  'yyyy-MM-DD'
                                )}-${moment(selection.endDate).format(
                                  'yyyy-MM-DD'
                                )}`,
                              },
                            })
                          }}
                          moveRangeOnFirstSelection={false}
                        />
                      )}
                    </div>
                    <Button
                      variant="chelonia2"
                      onClick={() => setshowDate(!showDate)}
                    >
                      確認
                    </Button>
                  </InputGroup>
                </React.Fragment>
              )
            case 'select':
              return (
                <React.Fragment key={i}>
                  <Form.Label className="mb-1 mt-3 fw-bold text-chelonia">
                    {f.label}
                  </Form.Label>

                  <Form.Select
                    name={f.name}
                    type={f.type}
                    value={data[f.name] || ''}
                    onChange={onDataChange}
                    placeholder={f.placeholder}
                    onFocus={() => setshowDate(false)}
                  >
                    <option value="">未選擇</option>
                    {f.content.map((c) => (
                      <option value={c}>{c}</option>
                    ))}
                  </Form.Select>
                </React.Fragment>
              )
            case 'file':
              return (
                <React.Fragment key={i}>
                  <Form.Label className="mb-1 mt-3 fw-bold text-chelonia">
                    {f.label}
                  </Form.Label>

                  <Form.Control
                    name={f.name}
                    type={f.type}
                    onChange={async (e) => {
                      // const formData = new FormData()
                      // formData.append('file', e.target.files[0])
                      onDataChange({
                        target: {
                          name: f.name,
                          value: e.target.files[0],
                        },
                      })
                      console.log('file append')
                      const temp = URL.createObjectURL(e.target.files[0])
                      const audio = document.createElement('audio')
                      audio.muted = true
                      console.log('audio created')
                      const source = document.createElement('source')
                      source.src = temp // --> blob URL
                      audio.preload = 'metadata'
                      audio.appendChild(source)
                      console.log('reading metadata')
                      audio.onloadedmetadata = () => {
                        console.log(audio.duration)
                        setDuration(audio.duration)
                      }
                      // const uploaded = await apiServices.data({
                      //   path: `material/file`,
                      //   method: 'post',
                      //   data: formData,
                      //   contentType: 'multipart/form-data',
                      // })
                      // console.log(uploaded)
                    }}
                    placeholder={f.placeholder}
                    onFocus={() => setshowDate(false)}
                  />
                </React.Fragment>
              )
            default:
              return (
                <React.Fragment key={i}>
                  <Form.Label className="mb-1 mt-3 fw-bold text-chelonia">
                    {f.label}
                  </Form.Label>

                  <Form.Control
                    name={f.name}
                    type={f.type}
                    value={data[f.name] || ''}
                    onChange={onDataChange}
                    placeholder={f.placeholder}
                    onFocus={() => setshowDate(false)}
                  />
                </React.Fragment>
              )
          }
        })}
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button
          className="ms-auto"
          style={{ boxShadow: 'none' }}
          variant="secondary"
          onClick={() => handleClose()}
        >
          取 消
        </Button>
        <Button
          className="me-auto"
          style={{ boxShadow: 'none' }}
          variant="chelonia-dark"
          onClick={() => handleClose(true)}
        >
          確 認
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

function OrgManager() {
  const [warn, setWarn] = useState({
    show: false,
    text: '',
    handleClose: () => {},
  })
  if (false) console.log(setWarn)

  const { auth } = useContext(AuthContext)
  const { setToast } = useContext(ToastContext)
  // const [users, setusers] = useState([])
  // const [orgs, setorgs] = useState([])
  const [orgUsers, setorgUsers] = useState([])
  // useEffect(() => {
  //   if (auth)
  //     setusers([
  //       auth,
  //       {
  //         username: '科學人',
  //         pk: '0001',
  //         organization: '泛科學',
  //       },
  //       {
  //         username: '知識人',
  //         pk: '0002',
  //         organization: '泛科知識',
  //       },
  //       {
  //         username: '學院人',
  //         pk: '0003',
  //         organization: '泛科學院',
  //       },
  //     ])
  //   setorgs([
  //     {
  //       name: '泛科學',
  //       pk: '0001',
  //     },
  //     {
  //       name: '泛科知識',
  //       pk: '0002',
  //     },
  //     {
  //       name: '泛科學院',
  //       pk: '0003',
  //     },
  //   ])
  // }, auth)

  // const getAdmins = async () => {
  //   const list = await apiServices.data({
  //     path: `org-admin/organization/${auth.orgs[auth.selectedOrg || 0].object_id}/user/`,
  //     method: 'get',
  //   })
  //   console.log(list)
  //   if (list.data) setusers(list.data)
  // }
  // const getOrgs = async () => {
  //   const list = await apiServices.data({
  //     path: `admin/organization/management/`,
  //     method: 'get',
  //   })
  //   console.log(list)
  //   if (list.data) setorgs(list.data)
  // }
  const getOrgUsers = async () => {
    const list = await apiServices.data({
      path: `org-admin/organization/${
        auth.orgs[auth.selectedOrg || 0].object_id
      }/user/`,
      method: 'get',
    })
    console.log(list)
    if (list.data) setorgUsers(list.data)
  }
  useEffect(() => {
    // getAdmins()
    // getOrgs()
    getOrgUsers()
  }, [])

  // const createAdmin = async (data) => {
  //   const res = await apiServices.data({
  //     path: `admin/user/`,
  //     method: 'post',
  //     data,
  //   })
  //   console.log(res)
  //   getAdmins()
  // }
  const createOrgUser = async (data) => {
    const res = await apiServices.data({
      path: `org-admin/organization/${
        auth.orgs[auth.selectedOrg || 0].object_id
      }/user/`,
      method: 'post',
      data: {
        source_user: {
          username: data.username,
          password: data.password,
        },
        source_organization: {
          name: data.organization,
        },
      },
    })
    console.log(res)
    if (res.error) {
      try {
        const errorData = res.error.response.data.data
        const error = Object.keys(errorData).reduce((prev, cur) => {
          const e = errorData[cur].reduce(
            (prevk, curk) => `${prevk}${curk}: ${errorData[cur][curk]}\n`,
            ''
          )
          return `${prev}${e}`
        }, '')
        setToast({
          show: true,
          text: error,
        })
      } catch (e) {
        setToast({
          show: true,
          text: '發生不明錯誤，請再試一次',
        })
      }
    }
    // getAdmins()
    // getOrgs()
    getOrgUsers()
  }

  const handleUpdateUser = async (id, permission) => {
    const res = await apiServices.data({
      path: `org-admin/organization/${
        auth.orgs[auth.selectedOrg || 0].object_id
      }/user-permission/${id}/`,
      method: 'PATCH',
      data: {
        permission,
      },
    })
    console.log(res)
    getOrgUsers()
  }

  const handleDeleteUser = async (id) => {
    const res = await apiServices.data({
      path: `org-admin/organization/${
        auth.orgs[auth.selectedOrg || 0].object_id
      }/user/${id}/`,
      method: 'delete',
    })
    console.log(res)
    // getAdmins()
    // getOrgs()
    getOrgUsers()
  }

  // const parseJSON = (inputString, fallback) => {
  //   if (inputString) {
  //     try {
  //       return JSON.parse(inputString)
  //     } catch (e) {
  //       return fallback
  //     }
  //   }
  //   return fallback
  // }

  const userform = useMemo(
    () => [
      {
        name: 'username',
        label: '帳號',
        placeholder: '',
        type: 'text',
      },
      {
        name: 'password',
        label: '密碼',
        placeholder: '',
        type: 'password',
      },
      // {
      //   name: 'organization',
      //   label: '所屬部門',
      //   placeholder: '',
      //   type: 'text',
      // },
    ],
    [orgUsers]
  )

  const organizationform = [
    {
      name: 'name',
      label: '名稱',
      placeholder: '',
      type: 'text',
    },
  ]

  const [form, setform] = useState(userform)

  const [selectedId] = useState('')

  const [show, setshow] = useState(false)
  const [data, setdata] = useState({})
  const onDataChange = (e) =>
    setdata({ ...data, [e.target.name]: e.target.value })
  useEffect(() => {
    if (show) {
      setdata(
        form.reduce(
          (prev, cur) => ({
            ...prev,
            [cur.name]: '',
          }),
          {}
        )
      )
    }
  }, [show, form])

  const [page, setpage] = useState('使用者管理')
  useEffect(() => {
    setform(page === '使用者管理' ? userform : organizationform)
  }, [page])

  const handleClose = (value) => {
    setshow(false)
    if (!value) return
    const { username, password } = data
    if (page === '使用者管理') {
      createOrgUser({
        username,
        password,
        organization: auth.orgs[auth.selectedOrg || 0].name,
      })
    }
  }

  const [deleteShow, setdeleteShow] = useState(false)
  const handleDeleteClose = (value) => {
    if (value) {
      handleDeleteUser(deleteShow)
    }
    setdeleteShow(false)
  }

  const [tempSearch, setTempSearch] = useState('')
  const [search, setSearch] = useState('')
  console.log(search)
  const [focus, setFocus] = useState(false)
  // const [selected, setselected] = useState('')

  const [showEdit, setshowEdit] = useState(false)
  const [editData, seteditData] = useState({})
  useEffect(() => {
    seteditData(
      (page === '使用者管理' ? orgUsers : []).find(
        ({ pk }) => pk === selectedId
      ) || {}
    )
  }, [selectedId])
  const onEditDataChange = (e) =>
    seteditData({ ...editData, [e.target.name]: e.target.value })

  const permissions = {
    ACCOUNT_ADMIN: '系統管理員',
    ACCOUNT_COMMON: '一般成員',
    ORGANIZATION_ADMIN: '組織管理員',
    ORGANIZATION_COMMON: '組織成員',
  }

  return (
    <Container className="d-flex flex-column pt-3 h-100">
      <Row className="ps-5 pe-4">
        <Col xs={3} className="d-flex flex-nowrap text-chelonia-light px-0">
          <Button
            className="border-0 text-nowrap h5 pb-0 mb-0"
            variant={page === '使用者管理' ? 'select-dark' : 'select-light'}
            onClick={() => {
              setpage(`使用者管理`)
            }}
            title="點擊以切換至使用者管理"
          >
            使用者管理
          </Button>
          {/* <h5 className="px-1 my-auto">|</h5>
          <Button
            className="border-0 text-nowrap h5 pb-0 mb-0"
            variant={page === '使用者管理' ? 'select-light' : 'select-dark'}
            onClick={() => {
              setpage(`組織管理`)
            }}
            title="點擊以切換至組織管理"
          >
            組織管理
          </Button> */}
          {/* <Form.Select
            className="w-75 h-100 fs-4 text-chelonia-light fw-bold border-0"
            aria-label="Default select example"
            onChange={(e) => setpage(e.target.value)}
            value={page}
          >
            {['組織管理', '使用者管理'].map((label, i) => (
              <option key={i} value={label}>
                {label}
              </option>
            ))}
          </Form.Select> */}
          {/* <div
            title={page}
            className="ps-3"
            style={{ cursor: 'pointer' }}
          >
            <NavDropdown
              title={page}
              className="my-auto text-chelonia-light fw-bold"
              id="nav-dropdown"
            >
              <NavDropdown.Item onClick={() => setpage('使用者管理')}>使用者管理</NavDropdown.Item>
              <NavDropdown.Item onClick={() => setpage('組織管理')}>組織管理</NavDropdown.Item>
            </NavDropdown>
          </div> */}
        </Col>
        <Col xs={4} className="d-flex justifu-content-end" />
        <Col xs={5} className="d-flex pe-0">
          <InputGroup>
            <Form.Control
              placeholder="輸入關鍵字以搜尋..."
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
              value={tempSearch}
              onChange={(event) => setTempSearch(event.target.value)}
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
              onKeyDown={(event) => {
                if (
                  event.key === 'Enter' &&
                  !event.nativeEvent.isComposing &&
                  focus
                )
                  setSearch(tempSearch)
              }}
            />
            <Button
              variant="outline-dark"
              id="button-addon2"
              title="搜 尋"
              onClick={() => setSearch(tempSearch)}
            >
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          </InputGroup>
          {page === '使用者管理' && (
            <Button
              className="ms-4 w-50"
              variant="chelonia-dark"
              onClick={() => {
                // setselectedId('')
                setshow(true)
              }}
            >
              {auth.isAdmin ? `建立新組織管理員` : `建立新角色`}
              &ensp;
              <FontAwesomeIcon icon={faCirclePlus} />
            </Button>
          )}
        </Col>
        {/* <Col xs={2} className="d-flex justifu-content-end">
          <Button
            className="w-75"
            variant="outline-chelonia"
            onClick={() => {
              setpage(page === '使用者管理' ? `組織管理` : `使用者管理`)
            }}
          >
            {page === '使用者管理' ? `組織管理` : `使用者管理`}
            &ensp;
            <FontAwesomeIcon icon={faCircleRight} />
          </Button>
          <Form.Select
            className="w-100 h-100"
            aria-label="Default select example"
            onChange={(e) => setpage(e.target.value)}
            value={page}
          >
            {['組織管理', '使用者管理'].map((label, i) => (
              <option key={i} value={label}>
                {label}
              </option>
            ))}
          </Form.Select>
        </Col> */}
      </Row>
      {page === '使用者管理' ? (
        <Row
          className="flex-grow-1 pt-3 pb-5 px-5 h-100"
          style={{ overflowY: 'auto', overflowX: 'hidden' }}
        >
          <ListGroup className="pe-0 h-100">
            {orgUsers.map(
              ({
                object_id,
                source_user,
                pk,
                organization_user_permission,
              }) => (
                <ListGroupItem className="d-flex row" key={pk}>
                  <Col
                    xs={3}
                    className="my-auto text-start oneLineEllipsis fs-7"
                    title={source_user.username}
                  >
                    <FontAwesomeIcon icon={faUser} />
                    &emsp;
                    {source_user.username}
                  </Col>
                  <Col xs={6} className="my-auto text-start ps-2">
                    <div className="fs-7 fw-regular text-chelonia d-flex">
                      權限｜&emsp;
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        label={
                          permissions[organization_user_permission.permission]
                        }
                        checked={
                          organization_user_permission.permission ===
                          'ORGANIZATION_ADMIN'
                        }
                        disabled={source_user.object_id === auth.pk}
                        onChange={() => {
                          handleUpdateUser(
                            organization_user_permission.object_id,
                            organization_user_permission.permission ===
                              'ORGANIZATION_ADMIN'
                              ? 'ORGANIZATION_COMMON'
                              : 'ORGANIZATION_ADMIN'
                          )
                        }}
                      />
                    </div>
                  </Col>
                  <Col xs={3} className="d-flex my-auto ms-auto">
                    {/* <Button
                      className="ms-auto"
                      style={{ boxShadow: 'none' }}
                      variant="edit"
                      onClick={() => {
                        setselectedId(pk)
                        setshowEdit(true)
                      }}
                      title="重 新 命 名"
                      size
                    >
                      <FontAwesomeIcon icon={faPenToSquare} />
                    </Button> */}
                    <Button
                      className="ms-auto"
                      style={{ boxShadow: 'none' }}
                      variant="red"
                      onClick={() => setdeleteShow(object_id)}
                      title="刪 除"
                    >
                      <FontAwesomeIcon icon={faTrashCan} />
                    </Button>
                  </Col>
                </ListGroupItem>
              )
            )}
          </ListGroup>
        </Row>
      ) : (
        <Row
          className="flex-grow-1 pt-3 pb-5 px-5 h-100"
          style={{ overflowY: 'auto', overflowX: 'hidden' }}
        >
          <div className="d-flex ps-3 border">
            <h5 className="m-auto text-chelonia-light">目前尚無資料</h5>
          </div>
        </Row>
      )}
      <ProjectModal
        setting={{
          show,
          form,
          // duration,
          // setDuration,
          // defaultValue: selectedId
          //   ? list.find((l) => l.material_id === selectedId)
          //   : {},
          data,
          onDataChange,
          defaultValue: {},
          handleClose,
        }}
      />
      <DeleteModal
        setting={{
          show: deleteShow,
          name: orgUsers.find(({ object_id }) => object_id === deleteShow)
            ?.source_user.username,
          handleClose: handleDeleteClose,
        }}
      />
      {warn.show && <ClipEndModal setting={warn} />}
      {selectedId && showEdit && (
        <Modal
          style={{ zIndex: '1502' }}
          show={showEdit}
          handleClose={() => setshowEdit(false)}
        >
          <Modal.Header className="h5 text-chelonia">
            {page === '使用者管理' ? '編輯使用者' : '編輯部門'}
          </Modal.Header>
          {page === '使用者管理' ? (
            <Modal.Body className="p-4">
              <Form.Label className="mb-1 mt-3 fw-bold text-chelonia">
                使用者名稱
              </Form.Label>

              <Form.Control
                type="text"
                value={editData.name}
                onChange={(e) =>
                  seteditData({
                    ...editData,
                    name: e.target.value,
                  })
                }
                placeholder="輸入名稱"
              />
              <Form.Label className="mb-1 mt-3 fw-bold text-chelonia">
                所屬部門
              </Form.Label>

              <div
                className="input-container"
                style={{
                  minWidth: '95%',
                  maxWidth: '95%',
                  overflow: 'scroll',
                }}
              >
                {(editData.organization || '泛科學').split(',').map((key) => (
                  <div className="input-tag d-flex" key={key}>
                    <span className="text-nowrap">{key}</span>
                    <FontAwesomeIcon
                      className="ms-2 fs-7 my-auto"
                      style={{
                        cursor: 'pointer',
                      }}
                      icon={faTimes}
                      onClick={() =>
                        onEditDataChange({
                          target: {
                            name: 'organization',
                            value: (editData.organization || '泛科學')
                              .split(',')
                              .filter((t) => t !== key)
                              .join(),
                          },
                        })
                      }
                    />
                  </div>
                ))}
              </div>
              {['泛科學', '泛科知識', '泛科學院'].map((tag) => (
                <Button
                  variant="outline-chelonia"
                  className="w-auto mx-1 my-1"
                  style={{ flexWrap: 'wrap' }}
                  size="sm"
                  key={tag}
                  onClick={() =>
                    onEditDataChange({
                      target: {
                        name: 'organization',
                        value: `${
                          editData.organization
                            ? `${editData.organization},`
                            : ''
                        }${tag}`,
                      },
                    })
                  }
                  disabled={(editData.organization || '泛科學').includes(tag)}
                >
                  {tag}
                </Button>
              ))}
            </Modal.Body>
          ) : (
            <Modal.Body className="p-4">
              <Form.Label className="mb-1 mt-3 fw-bold text-chelonia">
                部門名稱
              </Form.Label>

              <Form.Control
                type="text"
                value={editData.name}
                onChange={(e) =>
                  seteditData({
                    ...editData,
                    name: e.target.value,
                  })
                }
                placeholder="輸入名稱"
              />
            </Modal.Body>
          )}
          <Modal.Footer className="justify-content-center">
            <Button
              className="ms-auto me-2"
              style={{ boxShadow: 'none' }}
              variant="secondary"
              onClick={() => setshowEdit(false)}
            >
              取 消
            </Button>
            <Button
              className="me-auto"
              style={{ boxShadow: 'none' }}
              variant="chelonia-dark"
              onClick={() => {
                setshowEdit(false)
                // if (page === '使用者管理') {
                //   setorgs(
                //     orgs.map((org) =>
                //       org.pk === selectedId
                //         ? {
                //             ...org,
                //             name: editData.name,
                //           }
                //         : org
                //     )
                //   )
                // }
                // handleModify(editData.type, selectedId, {
                //   name: editData.name,
                // })
              }}
            >
              確 認
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  )
}

DeleteModal.propTypes = {
  setting: PropTypes.shape().isRequired,
}

ProjectModal.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default OrgManager
