/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Row } from 'react-bootstrap'
import {
  Welcome,
  Home,
  Hightlights,
  MaterialManagement,
  VideoManagement,
  Historical,
  Aisearch,
  UsersManager,
} from './pages'

import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import 'react-image-crop/dist/ReactCrop.css'
import { AppWrapper, ContextProvider } from './components'

function App() {
  return (
    <div className="App overflow-hidden">
      <Router>
        <ContextProvider>
          <AppWrapper>
            <Row
              className="flex-grow-1 px-0"
              style={{ overflowY: 'auto', overflowX: 'hidden' }}
            >
              <Routes className="px-0">
                <Route path="/Home" element={<Home />} />
                <Route path="/allVideo" element={<Home />} />
                <Route path="/Hightlights" element={<Hightlights />} />
                <Route path="/historical" element={<Historical />} />
                <Route path="/aisearch" element={<Aisearch />} />
                <Route path="/UsersManager" element={<UsersManager />} />
                <Route path="/Hightlights" element={<Hightlights />} />
                <Route
                  path="/VideoManagement/:project_id"
                  element={<VideoManagement />}
                />
                <Route
                  path="/MaterialManagement"
                  element={<MaterialManagement />}
                />
                <Route path="/" element={<Welcome />} />
                <Route path="/*" element={<Home />} />
              </Routes>
            </Row>
          </AppWrapper>
        </ContextProvider>
      </Router>
    </div>
  )
}

export default App
