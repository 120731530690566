import React from 'react'
import { Container, Row } from 'react-bootstrap'
import { FilmEditor } from '../components'

function VideoManagement() {
  return (
    <Container fluid className="d-flex flex-column" style={{ height: '100%' }}>
      <Row className="h-100">
        <FilmEditor />
      </Row>
    </Container>
  )
}

export default VideoManagement
