/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleExclamation,
  faCirclePlus,
  faPenToSquare,
  faTrashCan,
  faSearch,
  faScissors,
} from '@fortawesome/free-solid-svg-icons'
// import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import { DateRange } from 'react-date-range'
import {
  Row,
  Col,
  Button,
  ListGroup,
  ListGroupItem,
  Form,
  Modal,
  InputGroup,
  Container,
} from 'react-bootstrap'
import { UploaderContext } from './ContextProvider'

function DeleteModal({ setting }) {
  const { show, name, handleClose } = setting

  return (
    <Modal
      style={{ zIndex: '1501' }}
      show={show}
      onHide={() => handleClose()}
      className="py-2 px-4"
    >
      <Modal.Header closeButton />
      <Modal.Body className="p-4">
        <div className="d-flex">
          <FontAwesomeIcon
            className="px-0 m-auto text-chelonia text-center"
            style={{
              height: '100px',
            }}
            icon={faCircleExclamation}
          />
        </div>
        <h5 className="text-center lh-lg text-chelonia">
          <br />
          警告！刪除後無法復原！
          <br />
          仍要刪除
          <span className="text-danger">{`專案「${name}」`}</span>
          嗎？
        </h5>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button
          className="ms-auto me-2"
          style={{ boxShadow: 'none' }}
          variant="secondary"
          onClick={() => handleClose()}
        >
          取 消
        </Button>
        <Button
          className="me-auto"
          style={{ boxShadow: 'none' }}
          variant="chelonia-dark"
          onClick={() => handleClose(true)}
        >
          確 認
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

function ProjectModal({ setting }) {
  const { show, form, defaultValue = {}, handleClose } = setting
  const [showDate, setshowDate] = useState(false)

  const [data, setdata] = useState({})
  const onDataChange = (e) =>
    setdata({ ...data, [e.target.name]: e.target.value })

  useEffect(() => {
    if (show) {
      setshowDate(false)
      setdata(
        form.reduce(
          (prev, cur) => ({
            ...prev,
            [cur.name]: defaultValue ? defaultValue[cur.name] : '',
          }),
          {}
        )
      )
    }
  }, [show, defaultValue])
  const [date, setdate] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  })
  return (
    <Modal
      style={{ zIndex: '1501' }}
      show={show}
      onHide={() => handleClose()}
      className="py-2 px-4"
    >
      <Modal.Header className="h5" closeButton>
        {defaultValue.setting ? `編輯專案` : `新增／編輯專案`}
      </Modal.Header>
      <Modal.Body className="p-4 pb-5">
        {form.map((f, i) => (
          <React.Fragment key={i}>
            <Form.Label className="mt-3 mb-1 fw-bold text-chelonia">
              {f.label}
            </Form.Label>
            {f.type === 'date' ? (
              <InputGroup>
                <Form.Control
                  name={f.name}
                  type="text"
                  value={data[f.name] || f.placeholder}
                  placeholder={f.placeholder}
                  onFocus={() => setshowDate(!showDate)}
                  readOnly
                />
                <div
                  style={{
                    height: showDate ? '100%' : '0%',
                    transition: 'height .3s ease-in',
                    position: 'absolute',
                    left: '-50',
                  }}
                >
                  {showDate && (
                    <DateRange
                      ranges={[date]}
                      editableDateInputs
                      onChange={({ selection }) => {
                        setdate(selection)
                        onDataChange({
                          target: {
                            name: 'date',
                            value: `${moment(selection.startDate).format(
                              'yyyy-MM-DD'
                            )}-${moment(selection.endDate).format(
                              'yyyy-MM-DD'
                            )}`,
                          },
                        })
                      }}
                      moveRangeOnFirstSelection={false}
                    />
                  )}
                </div>
                <Button
                  variant="chelonia2"
                  onClick={() => setshowDate(!showDate)}
                >
                  確認
                </Button>
              </InputGroup>
            ) : (
              <Form.Control
                name={f.name}
                type={f.type}
                value={data[f.name] || ''}
                onChange={onDataChange}
                placeholder={f.placeholder}
                onFocus={() => setshowDate(false)}
              />
            )}
          </React.Fragment>
        ))}
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button
          className="ms-auto"
          style={{ boxShadow: 'none' }}
          variant="secondary"
          onClick={() => handleClose()}
        >
          取 消
        </Button>
        <Button
          className="me-auto"
          style={{ boxShadow: 'none' }}
          variant="chelonia-dark"
          onClick={() => handleClose(data)}
        >
          確 認
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

function VideoList() {
  const navigate = useNavigate()

  const { handleDelete, handleModify, handleAddProject, projects } =
    useContext(UploaderContext)
  // const [tempFile, settempFile] = useState(null)
  // useEffect(() => {
  //   if (tempFile) {
  //     handleUpload('', tempFile, (v) => {
  //       console.log(v)
  //     })
  //   }
  // }, [tempFile])

  const form = [
    {
      name: 'name',
      label: '專案名稱',
      placeholder: '請輸入專案名稱...',
      type: 'text',
    },
    {
      name: 'description',
      label: '專案標籤',
      placeholder: '請輸入標籤或備註內容...',
      type: 'text',
    },
  ]

  const [selectedId, setselectedId] = useState('')

  const [show, setshow] = useState(false)
  const handleClose = (value) => {
    setshow(false)
    if (!value) return
    if (selectedId) {
      handleModify('project', selectedId || '', value)
    } else {
      handleAddProject(value)
    }
  }

  const [deleteShow, setdeleteShow] = useState(false)
  const handleDeleteClose = (value) => {
    setdeleteShow(false)
    if (value) {
      handleDelete('project', selectedId)
      setselectedId('')
    }
  }
  const [tempSearch, setTempSearch] = useState('')
  const [search, setSearch] = useState('')
  const [focus, setFocus] = useState(false)
  // const [selected, setselected] = useState('')

  return (
    <Container className="d-flex flex-column pt-3 h-100">
      <Row className="px-5">
        <Col xs={5} className="d-flex">
          <h5 className="my-auto text-chelonia-light fw-bold">專案列表</h5>
        </Col>
        <Col xs={2} />
        <Col xs={5} className="d-flex pe-0">
          <InputGroup>
            <Form.Control
              placeholder="輸入專案名稱以搜尋..."
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
              value={tempSearch}
              onChange={(event) => setTempSearch(event.target.value)}
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
              onKeyDown={(event) => {
                if (
                  event.key === 'Enter' &&
                  !event.nativeEvent.isComposing &&
                  focus
                )
                  setSearch(tempSearch)
              }}
            />
            <Button
              variant="outline-dark"
              id="button-addon2"
              title="搜 尋"
              onClick={() => setSearch(tempSearch)}
            >
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          </InputGroup>
          <Button
            className="text-nowrap mx-2"
            variant="chelonia-dark"
            id="button-addon2"
            title="建 立 新 專 案"
            onClick={() => {
              setshow(true)
            }}
          >
            建立新專案&ensp;
            <FontAwesomeIcon icon={faCirclePlus} />
          </Button>
        </Col>
      </Row>
      <Row
        className="flex-grow-1 pt-3 pb-5 px-5 h-100"
        style={{ overflowY: 'auto', overflowX: 'hidden' }}
      >
        <ListGroup className="pe-0">
          {/* <ListGroupItem className="d-flex">
            <Row className="w-100">
              <Col
                xs={6}
                className="my-auto text-start oneLineEllipsis"
                title="專案01"
              >
                名稱｜project test 001
              </Col>
              <Col
                xs={3}
                className="my-auto text-secondary text-start oneLineEllipsis"
              >
                標籤｜test project
              </Col>
              <Col xs={3} className="d-flex">
                <Button
                  className="ms-auto me-2"
                  style={{ boxShadow: 'none' }}
                  variant="edit me-2"
                  // onClick={() => {
                  //   setselectedId(object_id)
                  //   setshow(true)
                  // }}
                  title="重 新 命 名"
                >
                  <FontAwesomeIcon icon={faPenToSquare} />
                </Button>
                <Button
                  className="me-2"
                  style={{ boxShadow: 'none' }}
                  variant="red"
                  // onClick={() => {
                  //   setselectedId(object_id)
                  //   setdeleteShow(true)
                  // }}
                  title="刪 除 專 案"
                >
                  <FontAwesomeIcon icon={faTrashCan} />
                </Button>

                <h2
                  className="my-auto text-grey"
                  style={{ userSelect: 'none' }}
                >
                  ｜
                </h2>

                <Button
                  className="me-0"
                  style={{ boxShadow: 'none' }}
                  variant="edit"
                  onClick={() => navigate(`/VideoManagement/9372`)}
                  title="影 片 上 傳 ＆ 剪 輯"
                >
                  <FontAwesomeIcon icon={faScissors} />
                </Button>
              </Col>
            </Row>
          </ListGroupItem> */}
          {projects && projects.length ? (
            <>
              {projects
                .filter(
                  ({ name, description }) =>
                    !search ||
                    (name && name.includes(search)) ||
                    (description && description.includes(search))
                )
                .map(({ object_id, name, description }, i) => (
                  <ListGroupItem className="d-flex" key={i}>
                    <Row className="w-100">
                      <Col
                        xs={6}
                        className="my-auto text-start oneLineEllipsis"
                        title={name}
                      >
                        名稱｜{name}
                      </Col>
                      <Col
                        xs={3}
                        className="my-auto text-secondary text-start oneLineEllipsis"
                      >
                        標籤｜{description || ''}
                      </Col>
                      <Col xs={3} className="d-flex">
                        <Button
                          className="ms-auto me-2"
                          style={{ boxShadow: 'none' }}
                          variant="edit me-2"
                          onClick={() => {
                            setselectedId(object_id)
                            setshow(true)
                          }}
                          title="重 新 命 名"
                        >
                          <FontAwesomeIcon icon={faPenToSquare} />
                        </Button>
                        <Button
                          className="me-2"
                          style={{ boxShadow: 'none' }}
                          variant="red"
                          onClick={() => {
                            setselectedId(object_id)
                            setdeleteShow(true)
                          }}
                          title="刪 除 專 案"
                        >
                          <FontAwesomeIcon icon={faTrashCan} />
                        </Button>

                        <h2
                          className="my-auto text-grey"
                          style={{ userSelect: 'none' }}
                        >
                          ｜
                        </h2>

                        <Button
                          className="me-0"
                          style={{ boxShadow: 'none' }}
                          variant="edit"
                          // onClick={() => setId(time_id || range_id || draft_id)}
                          onClick={() =>
                            navigate(`/VideoManagement/${object_id || i}`)
                          }
                          title="影 片 上 傳 ＆ 剪 輯"
                        >
                          <FontAwesomeIcon icon={faScissors} />
                        </Button>
                      </Col>
                    </Row>
                  </ListGroupItem>
                ))}
            </>
          ) : (
            ''
            // <div className="d-flex ps-3 border">
            //   <h5 className="m-auto text-chelonia-light">目前尚無資料</h5>
            // </div>
          )}
        </ListGroup>
      </Row>
      <ProjectModal
        setting={{
          show,
          form,
          defaultValue: selectedId
            ? projects.find((l) => l.object_id === selectedId)
            : {},
          handleClose,
        }}
      />
      <DeleteModal
        setting={{
          show: deleteShow,
          name: projects.find((l) => l.object_id === selectedId)?.name,
          handleClose: handleDeleteClose,
        }}
      />
    </Container>
  )
}

DeleteModal.propTypes = {
  setting: PropTypes.shape().isRequired,
}

ProjectModal.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default VideoList
