import React, { useContext } from 'react'
import { Container, Row } from 'react-bootstrap'
import { Manager, OrgManager } from '../components'
import { AuthContext } from '../components/ContextProvider'

function UsersManager() {
  const { auth } = useContext(AuthContext)
  return (
    <Container fluid className="d-flex flex-column" style={{ height: '100%' }}>
      <Row className="h-100">{auth.isAdmin ? <Manager /> : <OrgManager />}</Row>
    </Container>
  )
}

export default UsersManager
