import React, { useState, useEffect, useContext } from 'react'
import { Row, Col, Container, Form, Button, Image } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { ToastContext } from '../components/ContextProvider'
import apiServices from '../services/apiServices'
import { CNLogo } from '../assets'

function Welcome() {
  // const { setAuth } = useContext(AuthContext)
  const { setToast } = useContext(ToastContext)
  const navigate = useNavigate()

  const [data, setdata] = useState({
    email: '',
    password: '',
  })
  const onDataChange = (e) =>
    setdata({ ...data, [e.target.name]: e.target.value })

  const [type, settype] = useState('LOGIN')
  const form = {
    LOGIN: [
      {
        name: 'username',
        label: '',
        placeholder: '帳號',
        type: 'text',
      },
      {
        name: 'password',
        label: '',
        placeholder: '密碼',
        type: 'password',
      },
    ],
    Register: [
      {
        name: 'username',
        label: '',
        placeholder: '帳號',
        type: 'text',
      },
      {
        name: 'password1',
        label: '',
        placeholder: '密碼',
        type: 'password',
      },
      {
        name: 'password2',
        label: '',
        placeholder: '確認密碼',
        type: 'password',
      },
    ],
  }
  useEffect(() => {
    setdata(form[type].reduce((prev, cur) => ({ ...prev, [cur.name]: '' }), {}))
  }, [type])

  const handleLogin = async () => {
    const res =
      type === 'LOGIN'
        ? await apiServices.login(data)
        : await apiServices.register(data)
    if (res.error) {
      setToast({
        show: true,
        text: Object.keys(res.error.response.data.data)
          .map((key) => `${key}: ${res.error.response.data.data[key]}`)
          .join('/n'),
      })
    } else {
      navigate('/Home')
      window.location.reload()
    }
    // navigate('/Home')
    // window.location.reload()
    // if (!token) {
    //   setToast({ show: true, text: '登 入 失 敗' })
    //   return
    // }
    // document.cookie = `token=${token}; Domain=${window.location.hostname}; Path=/;`
    // const { user } = await apiServices.me()
    // setAuth({
    //   authed: true,
    //   ...user,
    // })
  }

  return (
    <Container
      fluid
      className="welcomePage bg-02 d-flex flex-column h-100 pe-5"
    >
      <div
        style={{
          height: '26%',
        }}
      />
      <div className="mx-auto">
        <Row className="d-flex py-0 pt-4">
          <Image
            src={CNLogo}
            className="d-flex m-auto"
            style={{ width: '25rem', opacity: '1' }}
          />
        </Row>
        <Row className="h6 fw-light mt-0 w-100 pt-0">
          <Col xs={10} className="my-auto ms-auto">
            {form[type].map((f, i) => (
              <React.Fragment key={i}>
                <Form.Control
                  name={f.name}
                  type={f.type}
                  value={data[f.name]}
                  onChange={onDataChange}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter' && !event.nativeEvent.isComposing)
                      handleLogin()
                  }}
                  placeholder={f.placeholder}
                  size="sm"
                  className="m-2"
                  style={{ opacity: '.8' }}
                />
              </React.Fragment>
            ))}
          </Col>
          <Col xs={2} className="d-flex my-auto me-auto">
            <Button
              variant="outline-dark"
              size="sm"
              className="my-auto"
              style={{ cursor: 'pointer' }}
              title="LOGIN"
              onClick={() => handleLogin(data)}
            >
              {type}
            </Button>
          </Col>
        </Row>
        <Row>
          <span
            className="fs-7"
            style={{
              textDecorationLine: 'underline',
              cursor: 'pointer',
            }}
            onClick={() => settype(type === 'LOGIN' ? 'Register' : 'LOGIN')}
            aria-hidden
          >
            {type === 'LOGIN' ? 'Not Registered? Click Here' : 'Login'}
          </span>
        </Row>
      </div>
    </Container>
  )
}

export default Welcome
