import React from 'react'
import { Container, Row } from 'react-bootstrap'
import { VideoList } from '../components'

function Home() {
  return (
    <Container fluid className="h-100 d-flex flex-column">
      <Row className="h-100">
        <VideoList />
      </Row>
    </Container>
  )
}

export default Home
